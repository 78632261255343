import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'livenessMultiChoice',
})
export class LivenessMultiChoicePipe implements PipeTransform {
  transform(
    value: string,
    livenessChoices: {
      label: string;
      value: string;
      context: {
        label: string;
        label_en: string;
      };
    }[],
    language: string,
  ): string {
    console.log(value);
    console.log(livenessChoices);
    console.log(language);
    if (language === 'en') {
      const findItem = livenessChoices.find(
        (item) => item.value === value,
      );
      if (findItem) {
        return findItem.context.label_en;
      }
      return '-';
    } else {
      const findItem = livenessChoices.find(
        (item) => item.value.toLowerCase() === value.toLowerCase(),
      );
      if (findItem) {
        return findItem.context.label;
      }
      return '-';
    }
  }
}
