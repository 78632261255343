import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetUserById } from 'src/app/store/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private store: Store) {}
  checkAccess(roles: string[]) {
    if (
      !this.store.selectSnapshot<string>((state) => state.auth?.role)
    ) {
      this.store.dispatch(GetUserById);
    }
    const role = this.store.selectSnapshot<string>(
      (s) => s.auth.role,
    );
    return roles.includes(role);
  }

  checkPermissionAccess(permissions: string[]) {
    if (
      !this.store.selectSnapshot<string>((state) => state.auth?.role)
    ) {
      this.store.dispatch(GetUserById);
    }
    const userPermissions = this.store.selectSnapshot<{
      [key: string]: boolean;
    }>((s) => s.auth.permissions);
    const hasPermission = permissions.some((permission) => {
      return userPermissions[permission];
    });
    return hasPermission;
  }
}
