import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';

@Directive({
  selector: '[appSidebarPermission]',
})
export class SidebarPermissionDirective {
  private permissions: string[] = [''];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permission: PermissionService,
  ) {}

  @Input()
  set appSidebarPermission(val: string[]) {
    this.permissions = val;
    this.updateView();
  }

  private async updateView() {
    const hasPermission = await this._checkSidebarPermission();
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private _checkSidebarPermission() {
    return this.permission.checkPermissionAccess(this.permissions);
  }
}
