import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../core/http/api.service';
import { API_URL } from './api.constant';
import { MemoService } from '../../modules/memos/service/memo.service';
import { RESPONSE$ } from '../utils/constants';
import { Store } from '@ngxs/store';
import { filter, take } from 'rxjs/operators';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  memoNotificationCount = 0;

  constructor(
    private router: Router,
    private http: ApiService,
    private memoService: MemoService,
    private store: Store,
  ) {}

  updateNotificationCount() {
    this.store
      .select((state) => state.auth?.access)
      .pipe(
        filter((access) => !!access), // Wait until access is truthy
        take(1),
      )
      .subscribe((access) => {
        if (access) {
          this.memoService
            .getMyTaskBadge()
            .pipe(take(1))
            .subscribe({
              next: (memos: { count: number }) => {
                this.memoNotificationCount = memos.count;
              },
              error: (err) => {
                this.store.dispatch(new ErrorNotification(err));
              },
            });
        }
      });
  }

  // Feature Notifications

  getAllNotification(params?): RESPONSE$ {
    return this.http.get(API_URL.notification, params);
  }

  markAllAsRead() {
    return this.http.post(API_URL.notification + 'read-all/', null);
  }

  updateIsRead(id) {
    return this.http.post(
      API_URL.notification + id + '/' + 'read' + '/',
      id,
    );
  }
}

export interface MutedNotification {
  id?: number;
  disabled?: boolean;
  channel: string;
  action: string;
}

export interface NotificationList {
  group: string;
  actions: Actions[];
}

export interface Actions {
  label: string;
  value: string;
}

export interface NotificationChannel {
  name: string;
  label: string;
}
