<div class="modal-header">
  <h4 class="mb-0">
    {{ "AUTH.Password Expiry Warning" | translate }}
  </h4>
  <svg-icon src="assets/images/icons/btn-close.svg"
      [svgStyle]="{ 'width.px': 20, 'height.px': 20 ,'margin-top.px':5}"
      class="pointer icon-close"
      (click)="close()"></svg-icon>
</div>

<div class="modal-body text-center">
  <label class="f-18">
    {{ "AUTH.Your password will expire" | translate }} {{ data.days_before_expire }} {{ "AUTH.Days" | translate }}
    <br>
    {{ "AUTH.Please change your password to maintain" | translate }}
  </label>
</div>

<div class="modal-footer d-flex justify-content-center">
  <button class="btn btn-confirm"
      type="button"
      appSetTheme
      (click)="close()">
    {{ 'AUTH.Agree' | translate }}
  </button>
</div>