import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from '../../../shared/service/api.constant';
import {
  CommentList,
  ComponentData,
  ComponentSessionConfig,
  ConsentVersions,
  MemoDetail,
  MemoListDetail,
} from '../model/memo.model';
import { UploadMemoPayload } from '../model/template.model';
import { FormInputMarkerIdentity } from '../components/upload-memos/form-inputs/form-inputs.model';
import {
  Marker,
  PreviewMarker,
} from '../components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';

@Injectable({
  providedIn: 'root',
})
export class MemoService {
  headers: HttpHeaders;
  historyText: string;
  loaList: any;
  private setLoa = new Subject();
  data = this.setLoa.asObservable();

  changeTable: any;
  private setTable = new Subject();
  tableData = this.setTable.asObservable();
  requestPo: Observable<any>;

  private setFormat = new Subject();
  inputDateFormat = this.setFormat.asObservable();

  private setWidthForDate = new Subject();
  widthForDate = this.setWidthForDate.asObservable();

  positionKeys = [
    'positions',
    'approved_date_positions',
    'comment_positions',
  ];

  constructor(private http: ApiService) {}

  getDepartmentList(data?: { [type: string]: string }) {
    return this.http.get(API_URL.departments, data);
  }

  previewMemoPDF(data: { [type: string]: string }) {
    return this.http.pdfPost(API_URL.memo_preview, data);
  }

  // My task
  getMyTaskList(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task, params);
  }

  getMyTaskBadge(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task + 'badge/', params);
  }

  /**
   * Get the unique sessions property from `ComponentData`.
   * If all components is undefined a session then it return empty array.
   */
  getInputComponentSessions(
    inputComponents: ComponentData[],
    ignoreUndefinedGroup = true,
  ): ComponentSessionConfig[] {
    const sessionNames: string[] = [];
    const sessions: ComponentSessionConfig[] = [];
    const retrieveSessionName = (
      session: string | ComponentSessionConfig,
    ) => {
      if (typeof session === 'object') {
        return session.sessionName;
      }
      return session;
    };
    inputComponents.forEach((component) => {
      const sessionName = retrieveSessionName(component.session);
      if (ignoreUndefinedGroup && !sessionName) {
        return;
      }
      const isDuplicated = sessionNames.includes(sessionName);
      if (!isDuplicated && component.componentName != null) {
        sessionNames.push(sessionName);
        sessions.push(this.rewrapSessionConfig(component.session));
      }
    });
    return sessions;
  }

  // RealMemo
  getGeneralMemoList(params?): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.general_memo,
      params,
    );
  }

  getMemoListCSV(data) {
    // url นี้จะไม่ return file อีกแล้ว แต่จะ return celery task id มาแทน
    return this.http.get(API_URL.general_memo + 'excel/', data);
  }

  getMemoFiles(data) {
    return this.http.getBlob(
      API_URL.general_memo + 'download-multiple-pdf/',
      data,
    );
  }

  getMemoReferenceById(id) {
    return this.http.get(API_URL.memos + id + '/references/');
  }

  getConsentVersionsById(id: string): Observable<ConsentVersions[]> {
    return this.http.get(
      API_URL.general_memo + id + '/consent-form-versions/',
    );
  }

  updateMemoReferenceById(id, data) {
    return this.http.post(
      API_URL.memos + id + '/update-references/',
      data,
    );
  }

  // Action
  createMemo(data: UploadMemoPayload): Observable<MemoDetail> {
    return this.http.post(API_URL.memos, data);
  }

  updateMemo(id, data): Observable<MemoDetail> {
    return this.http.patch(API_URL.memos + id + '/', data);
  }

  updateAnnouncement(id, data, header?) {
    return this.http.post(
      API_URL.memos + id + '/announcement/',
      data,
      header,
    );
  }

  publishMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/publish/', data);
  }

  extendMemo(id, data) {
    return this.http.post(API_URL.memos + id + '/extend/', data);
  }

  reviseMemo(id) {
    return this.http.post(API_URL.memos + id + '/revise/', {});
  }

  recallMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/recall/', data);
  }

  approveMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/approve/', data);
  }

  rejectMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/reject/', data);
  }

  terminateMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/terminate/', data);
  }

  downloadMemo(id, data?) {
    return this.http.postResponseBlob(
      API_URL.memos + id + '/download-pdf/',
      data,
    );
  }

  getMemoDetail(id: number): Observable<MemoDetail> {
    return this.http.get(API_URL.memos + id + '/');
  }

  getMemoHistory(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memos_history, params);
  }

  getHistoryLogCSV(data) {
    return this.http.getBlob(API_URL.memos_history + 'excel/', data);
  }

  deleteMemo(id) {
    return this.http.delete(API_URL.memos + id + '/');
  }

  // Upload Blob

  uploadBlob(id, data) {
    return this.http.patch(API_URL.upload_memo_blob + id + '/', data);
  }
  uploadContractBlob(id, data) {
    return this.http.patch(
      API_URL.upload_contract_blob + id + '/',
      data,
    );
  }

  uploadConsentFormBlob(id, data) {
    return this.http.patch(
      API_URL.consent_form_memo_blob + id + '/',
      data,
    );
  }

  uploadMultiBlob(id, data) {
    return this.http.patch(
      API_URL.upload_memo_blob_multi + id + '/',
      data,
    );
  }

  uploadContractMultiBlob(id, data) {
    return this.http.patch(
      API_URL.upload_contract_blob_multi + id + '/',
      data,
    );
  }

  uploadConsentFormMultiBlob(id, data) {
    return this.http.patch(
      API_URL.consent_form_memo_multi_blob + id + '/',
      data,
    );
  }

  removeBlob(data) {
    return this.http.post(API_URL.remove_memo_blob, data);
  }

  removeContractBlob(data) {
    return this.http.post(API_URL.remove_contract_blob, data);
  }

  // Comment

  getCommentList(params): Observable<CommentList[]> {
    return this.http.get(API_URL.memo_comment, params);
  }

  createNewComment(data): Observable<CommentList> {
    return this.http.post(API_URL.memo_comment, data);
  }

  updateMemoRead(data) {
    return this.http.post(API_URL.memo_read, data);
  }

  deleteComment(id) {
    return this.http.delete(API_URL.memo_comment + id + '/');
  }

  // Attachment
  getMemoAttachment(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memo_attachment, params);
  }

  removeMemoAttachment(id) {
    return this.http.delete(API_URL.memo_attachment + id + '/');
  }

  updateMemoAttchment(id, data) {
    return this.http.patch(API_URL.memo_attachment + id + '/', data);
  }

  uploadMemoAttachment(data) {
    return this.http.post(API_URL.memo_bulk_attachment, data);
  }

  downloadMemoAttachment(id, data?) {
    return this.http.post(
      API_URL.memo_attachment + id + '/download/',
      data,
    );
  }

  logDownloadFile(
    id,
    log_type: 'rejected' | 'attachment' | 'comment',
  ) {
    return this.http.post(
      API_URL.memos + id + '/log-download-file/',
      { log_type },
    );
  }

  // Verify Duplicated Memo Number
  verifyMemoNumber(params) {
    return this.http.get(API_URL.memo_number_verify, params);
  }

  printFile(url) {
    return this.http.printFile(url);
  }

  getMemoTypes() {
    const params = { type: 'memo_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  getMemoUploadTypes() {
    const params = { type: 'memo_upload_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  setLoadLoaList(department) {
    this.loaList = department;
    this.setLoa.next(this.loaList);
  }

  setInputDate(date) {
    this.setFormat.next(date);
  }

  setWidthDate(event?) {
    this.setWidthForDate.next(event);
  }

  getMemoRevised(params) {
    return this.http.get(API_URL.memo_revised, params);
  }

  // acknowledgement
  getAcknowledge(params) {
    return this.http.get('/api/acknowledges/', params);
  }

  getAcknowledgeCSV(data) {
    return this.http.getBlob('/api/acknowledges/excel/', data);
  }

  // download file
  createDownloadFile(data: any, filename: string): void {
    // for IE10+
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  rewrapSessionConfig(
    session: string | ComponentSessionConfig,
  ): ComponentSessionConfig {
    if (typeof session === 'string') {
      return { sessionName: session };
    }
    return session;
  }

  updateMemoDetail(id, data) {
    return this.http.patch(API_URL.memo_detail + id + '/', data);
  }

  // trash
  getFinishedMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.finished_memo,
      params,
    );
  }

  bulkTrashMemo(data) {
    return this.http.post(API_URL.bulk_trash_memo, data);
  }

  bulkUntrashMemo(data) {
    return this.http.post(API_URL.bulk_untrash_memo, data);
  }

  bulkPermanentlyDeleteMemo(data) {
    return this.http.post(API_URL.bulk_permanently_delete_memo, data);
  }

  getFinishedMemoListCSV(data) {
    return this.http.get(API_URL.finished_memo + 'excel/', data);
  }

  exportMemoToGDrive(data) {
    return this.http.post(
      API_URL.finished_memo + 'export-gdrive/',
      data,
    );
  }
  getNdidCredit() {
    return this.http.get('/api/ndid/credit/');
  }

  getThaicomCredit() {
    return this.http.get('/api/thaicom/credit/');
  }

  removeAllMemoAttachment(memo_id) {
    return this.http.post('/api/memo-attachments/remove-all/', {
      memo_id,
    });
  }

  validateFiles(data) {
    return this.http.post('/api/validate-files/', data);
  }

  getContractList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(API_URL.contract, params);
  }
  getContractListCSV(data) {
    return this.http.get(API_URL.contract + 'excel/', data);
  }

  getContractFiles(data) {
    return this.http.getBlob(
      API_URL.contract + 'download-multiple-pdf/',
      data,
    );
  }

  getRequestEvidence(id, data, header?) {
    return this.http.post(
      '/api/memos/contract/' + id + '/request-evidences/',
      data,
      header,
    );
  }
  getSelfAttachment(id, data, header?) {
    return this.http.post(
      '/api/memos/contract/' + id + '/self-request-attachments/',
      data,
      header,
    );
  }

  uploadRequestAttachment(id, data, header?): Observable<any> {
    return this.http.post(
      '/api/memos/contract/' +
        id +
        '/self-upload-request-attachments/',
      data,
      header,
    );
  }

  uploadSelfieVideo(id, fd: FormData, header?): Observable<any> {
    return this.http.post(
      '/api/memos/contract/' + id + '/self-upload-selfie-video/',
      fd,
      header,
    );
  }
  testConvertAndResizeVideo(fd: FormData, header?): Observable<any> {
    return this.http.postResponseBlob(
      '/api/memos/contract/test-resize-convert-video/',
      fd,
      header,
    );
  }
  getEvidence(id) {
    return this.http.get('/api/memos/contract/' + id + '/evidence/');
  }

  getVideoSelfie(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/selfie-video/',
      data,
    );
  }

  getEvidenceAttachment(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/request-attachments/',
      data,
    );
  }

  postResubmit(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/resubmit-request/',
      data,
    );
  }

  confirmEvidence(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/confirm-evidence/',
      data,
    );
  }

  checkCredit(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/check-credit/',
      data,
    );
  }

  convertPreviewSignature(memo) {
    const memberIsApproved = [];

    memo.table_data.levels.forEach((level) => {
      level.members.forEach((member) => {
        member.is_approve = !!member.date_of_approve;
        memberIsApproved.push({
          ...member,
          level: `Level ${level.level}`,
        });
      });
    });

    const memoType = memo.consent_form;

    memberIsApproved.forEach((member) => {
      memoType.signature_position.forEach((value: PreviewMarker) => {
        const isPendingOrApproved = ['pending', 'approved'].includes(
          memo?.status,
        );

        if (isPendingOrApproved) {
          if (['date', 'memo number'].includes(value.name)) {
            delete value.positions;
          }

          if (
            value.name === 'custom' &&
            !value.positions?.consentDataType
          ) {
            value.positions = value.positions?.filter(
              (item: FormInputMarkerIdentity) => item.consentDataType,
            );
          }
        }

        const levelApproved = value.name
          .toLowerCase()
          .includes(member.level.toLowerCase());
        const nameApproved = value.name
          .toLowerCase()
          .includes(member.full_name.toLowerCase());

        if (
          (member.is_approve || memo?.status === 'approved') &&
          levelApproved &&
          nameApproved
        ) {
          delete value.approved_date_positions;
          delete value.positions;
          delete value.comment_positions;
        }
      });
    });

    return memoType.signature_position;
  }

  convertWidgetForPreview(previewSignature) {
    const marker = previewSignature;
    marker.forEach((marker: Marker) => {
      this.positionKeys.forEach((key) => {
        if (marker[key]?.length > 0) {
          marker[key]?.forEach((item: { enableSetting: boolean }) => {
            item.enableSetting = false;
          });
        }
      });
    });
    return marker;
  }
}
