import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Data,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { NavbarService } from '../components/navbar/navbar.service';
import { PermissionService } from '../services/permission.service';

import { Store } from '@ngxs/store';
import { tokenExpired } from 'src/app/shared/utils/utils';
import { GetUserById } from 'src/app/store/auth/auth.actions';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navbar: NavbarService,
    private permissions: PermissionService,
    private store: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const currentUser =
      this.store.selectSnapshot<string>((s) => s.auth?.access) ||
      this.authenticationService.currentUserValue;
    const userRole = this.store.selectSnapshot<string>(
      (s) => s.auth?.role,
    );
    const userPermission = this.store.selectSnapshot<{
      [key: string]: boolean;
    }>((s) => s.auth?.permissions);
    let setPasswordStore: boolean =
      this.store.selectSnapshot<boolean>(
        (s) => s.auth.is_set_password,
      ) &&
      this.store.selectSnapshot<boolean>(
        (s) => s.auth.is_password_valid,
      );
    if (setPasswordStore == null) {
      setPasswordStore =
        this.authenticationService.isSetPassword &&
        this.authenticationService.isPasswordValid;
    }
    if (currentUser) {
      if (route.data.role) {
        if (!userRole) {
          return this.store.dispatch(GetUserById).pipe(
            map(() => {
              return this.checkRole(
                this.store.selectSnapshot<string>((s) => s.auth.role),
                route.data.role,
                setPasswordStore,
              );
            }),
            tap({
              error: (error) => this.router.navigateByUrl('/login'),
            }),
          );
        } else {
          return this.checkRole(
            userRole,
            route.data.role,
            setPasswordStore,
          );
        }
      } else if (route.data.permissions) {
        if (!userPermission) {
          return this.store.dispatch(GetUserById).pipe(
            map(() => {
              return this.checkPermission(
                this.store.selectSnapshot<{
                  [key: string]: boolean;
                }>((s) => s.auth.permissions),
                route.data.permissions,
                setPasswordStore,
              );
            }),
            tap({
              error: (error) => this.router.navigateByUrl('/login'),
            }),
          );
        } else {
          return this.checkPermission(
            userPermission,
            route.data.permissions,
            setPasswordStore,
          );
        }
      } else {
        if (!userRole) {
          return this.store.dispatch(GetUserById).pipe(
            map(() => {
              return this.checkRole(
                this.store.selectSnapshot<string>((s) => s.auth.role),
                route.data,
                setPasswordStore,
              );
            }),
            tap({
              error: (error) => this.router.navigateByUrl('/login'),
            }),
          );
        } else {
          return this.checkRole(
            userRole,
            route.data,
            setPasswordStore,
          );
        }
      }
    } else {
      const tab = state.root?.queryParams?.tab;
      if (tab === 'review_evidence') {
        const parts = state.url.split('/');
        const id = parts[3]?.split('?')[0];
        const queryString = {
          id: id,
          tab: tab,
        };
        const encodedQueryString = btoa(JSON.stringify(queryString));
        this.router.navigate(['/login'], {
          queryParams: { returnUrlEvidence: encodedQueryString },
        });
        return false;
      }
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    // this.router.navigate(['**'], { queryParams: { returnUrl: state.url } });
    // not logged in so redirect to login page with the return url
    // this.store.dispatch(GetUserById).subscribe(async () => {
    //   resolve(
    //     this.checkRole(
    //       await this.store.selectSnapshot<string>(s => s.auth.role)
    //     )
    //   );
    // });
  }

  checkRole(
    userRole: string,
    roleStore: Data,
    setPasswordStore: boolean,
  ): boolean {
    if (!setPasswordStore) {
      this.router.navigateByUrl('/reset-password');
    }
    if (
      Object.values(roleStore).includes(userRole) ||
      !Object.values(roleStore).length
    ) {
      this.navbar.setActiveSidebar(true);
      return true;
    } else {
      this.router.navigate(['/page-not-found'], {
        queryParams: { permission: true },
      });
      return false;
    }
  }

  checkPermission(
    userPermission: {
      [key: string]: boolean;
    },
    permissionStore: Data,
    setPasswordStore: boolean,
  ): boolean {
    if (!setPasswordStore) {
      this.router.navigateByUrl('/reset-password');
    }
    const hasPermission = permissionStore.some((permission) => {
      return userPermission[permission];
    });
    if (hasPermission) {
      this.navbar.setActiveSidebar(true);
      return true;
    } else {
      this.router.navigate(['/page-not-found'], {
        queryParams: { permission: true },
      });
      return false;
    }
  }

  // tslint:disable-next-line:max-line-length
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      this.navbar.setActiveSidebar(true);
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
