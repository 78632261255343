import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  PDFDocumentProxy,
  PdfViewerComponent as ParentPdfViewerComponent,
} from 'ng2-pdf-viewer';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { featureFlag } from '../../../../environments/environment';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  _featureFlag = featureFlag;
  @Input() pdfFile;
  @Input() showDownload = false;
  // fix static space for scrollbar because default style it makes pdf viewer width,height calculation problem
  // https://gitlab.c0d1um.io/products/e-memo/memo-bls-new/-/issues/246
  @Input() scrollbarGutterStable = false;
  @Output() downloaded = new EventEmitter<void>();
  @Output() clickPreview = new EventEmitter<void>();
  @Input() checkPage: string;
  @Output() detectScroll = new EventEmitter<boolean>(false);
  @ViewChild('consentScrolling') consentScrolling!: ElementRef;
  themeList: ThemeList;
  currentDocumentPage: number;
  totalDocumentPage: number;
  isDocumentLoadingPage: boolean;
  httpHeaders;

  height;
  width;

  pdf;
  isLoading: boolean;

  @ViewChild(ParentPdfViewerComponent, { static: false })
  private pdfComponent: ParentPdfViewerComponent;
  @ViewChild('pdfView', { static: false })
  private pdfView: ElementRef;
  themeSubscription: Subscription[] = [];

  constructor(
    public themeService: ThemeService,
    private authenticationService: AuthenticationService,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    this.themeSubscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngAfterViewInit() {
    this.checkOverflow();
  }

  checkOverflow(): void {
    setTimeout(() => {
      if (this.consentScrolling) {
        const el = this.consentScrolling.nativeElement;
        const hasOverflow = el.scrollHeight - 1 > el.clientHeight;
        this.detectScroll.emit(!hasOverflow);
      }
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges | any): void {
    if (changes.pdfFile) {
      this.httpHeaders = this.authenticationService.httpHeader;
    }
  }

  ngOnDestroy(): void {
    this.themeSubscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  onLoadPdfComplete(pdf: PDFDocumentProxy): void {
    this.currentDocumentPage = 1;
    this.totalDocumentPage = pdf.numPages;
    this.isDocumentLoadingPage = false;
    this.pdf = pdf;
  }

  get documentPages(): number[] {
    return Array(this.totalDocumentPage)
      .fill(1)
      .map((value, index) => index + 1);
  }

  changeDocumentPage(page: number): void {
    this.currentDocumentPage = +page;
  }

  changeDocumentPageBack(): void {
    this.consentScrolling.nativeElement.scrollTop = 0;
    if (
      !this.isDocumentLoadingPage &&
      this.currentDocumentPage - 1 >= 1
    ) {
      this.currentDocumentPage--;
    }
  }

  changeDocumentPageNext(): void {
    this.consentScrolling.nativeElement.scrollTop = 0;
    if (
      !this.isDocumentLoadingPage &&
      this.currentDocumentPage + 1 <= this.totalDocumentPage
    ) {
      this.currentDocumentPage++;
    }
  }

  async pageRendered(): Promise<void> {
    if (!this.pdf) {
      return;
    }
    const page = await this.pdf.getPage(this.currentDocumentPage);
    const originalPageView = page.getViewport({ scale: 1 });
    const scale =
      this.pdfView.nativeElement.clientWidth / originalPageView.width;
    const viewport = page.getViewport({ scale });
    this.height = viewport.height;
    this.width = viewport.width;
    this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
  }

  getHeight(): string {
    if (
      this.checkPage === 'pageAck' ||
      this.checkPage === 'consent'
    ) {
      return '100%';
    } else {
      return '';
    }
  }
  getPdfViewClass(): string {
    if (this.checkPage === 'approvalEmailPage') {
      return 'approval-email-page';
    } else {
      return 'other-page';
    }
  }

  getPdfViewerClass(): string {
    if (this.pdf) {
      const baseClass = 'page-view bg-color';

      switch (this.checkPage) {
        case 'approvalEmailPage':
          return 'page-view-email bg-color';
        case 'consent':
          return 'info-box ' + baseClass;
        default:
          return baseClass;
      }
    } else {
      return '';
    }
  }

  onScroll(event: {
    target: {
      offsetHeight: number;
      scrollTop: number;
      scrollHeight: number;
    };
  }): void {
    if (!this.pdfFile) return;
    const isScrollAtBottom =
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight;
    const isLastPage =
      this.totalDocumentPage === 1 ||
      this.totalDocumentPage === this.currentDocumentPage;
    this.detectScroll.emit(isScrollAtBottom && isLastPage);
  }
}
